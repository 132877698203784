import { Component, OnInit } from '@angular/core';
import { QuotationService } from '../../../core/services/quotation.service';
import { Quotation } from '../../models/quotation.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Premium } from '../../models/plan-premium.model';
import { Location } from '@angular/common';
import { InsurancePlan } from '../../models/insurance-plan.model';

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrl: './details-page.component.scss',
})
export class DetailsPageComponent implements OnInit {
  // Variable to store Quotation Id
  quotationId: string;

  //  Variable to store quotation.
  quotation: Quotation;

  // Variable to store Customer data.
  Customers: { name: string; age: number; fromArea: string }[] = [];

  // Boolean flag to check loading status of the Page.
  isLoading: boolean = true;

  // Variable to store SelectedPlans
  selectedPlans: Premium[] = [];
  selectedPlanDetails: InsurancePlan[] = [];

  constructor(
    private quotationService: QuotationService,
    private active: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    //  Get the Quotation Id from the URL.
    this.active.params.subscribe((params: Params) => {
      this.quotationId = params['quotationId'];
    });

    // Get Quotation data if quotation id exists.
    if (this.quotationId) {
      this.quotationService
        .getQuotation(this.quotationId)
        .subscribe((quotation: Quotation) => {
          this.quotation = quotation;
          if (
            quotation?.selectedPlans &&
            quotation?.selectedPlans?.length > 0
          ) {
            quotation.selectedPlans.forEach((plan, i) => {
              if (plan.selected) {
                this.selectedPlans.push(plan);
                this.selectedPlanDetails.push(quotation?.planDetails[i]);
              }
            });
            const plans = quotation?.selectedPlans[0]?.premiumData;
            plans.forEach((plan) => {
              const temp = {
                name: plan.emirateName,
                age: plan.age,
                fromArea: plan.fromArea,
              };
              this.Customers.push(temp);
            });
          }
          this.isLoading = false;
        });
    } else {
      // else route back to quotations.
      this.router.navigate(['quotations']);
    }
  }

  /**
   * To get the css class of the the quotation status.
   * @param { string } status
   * @returns { string }
   */
  statusClass(status: string): string {
    switch (status) {
      case 'Created':
        return 'badge rounded-pill bg-primary';
      case 'Drafts':
        return 'badge rounded-pill bg-secondary';
      case 'Lost':
        return 'badge rounded-pill bg-danger';
      case 'Closed won':
        return 'badge rounded-pill bg-success';
      case 'Request':
        return 'badge rounded-pill bg-info';
      case 'Renewals':
        return 'badge rounded-pill bg-warning';

      default:
        return 'badge rounded-pill bg-dark';
    }
  }

  /**
   * Function to go back to the previous route.
   */
  goBack() {
    this.location.back();
  }
}
