<form [formGroup]="filterForm">
  <div class="modal-wrap">
    <div class="modal-header border-none">
      <h5 class="modal-title">Select Filters</h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="activeModal.close()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6 mb-4">
          <label class="form-label mb-2">Select Time Interval</label>
          <div class="d-flex flex-column mb-2">
            <div class="form-check form-check-inline mb-1">
              <input
                id="today"
                type="radio"
                class="form-check-input"
                value="today"
                formControlName="dateFilter"
                (change)="toggleCustom($event)"
              />
              <label class="form-check-label" for="today"> Today </label>
            </div>
            <div class="form-check form-check-inline mb-1">
              <input
                id="week"
                type="radio"
                class="form-check-input"
                value="week"
                formControlName="dateFilter"
                (change)="toggleCustom($event)"
              />
              <label class="form-check-label" for="week"> This Week </label>
            </div>
            <div class="form-check form-check-inline mb-1">
              <input
                id="month"
                type="radio"
                class="form-check-input"
                value="month"
                formControlName="dateFilter"
                (change)="toggleCustom($event)"
              />
              <label class="form-check-label" for="month"> This Month </label>
            </div>
            <div class="form-check form-check-inline mb-1">
              <input
                id="year"
                type="radio"
                class="form-check-input"
                value="year"
                formControlName="dateFilter"
                (change)="toggleCustom($event)"
              />
              <label class="form-check-label" for="year"> This Year </label>
            </div>
            <div class="form-check form-check-inline mb-1">
              <input
                id="custom"
                type="radio"
                class="form-check-input"
                value="custom"
                formControlName="dateFilter"
                (change)="toggleCustom($event)"
              />
              <label class="form-check-label" for="custom"> Custom </label>
            </div>
          </div>
          <div *ngIf="customFilter" class="">
            <div class="mb-4">
              <label for="dob" class="form-label"
                >From Date <span>*</span>
              </label>
              <div class="input-group">
                <input
                  id="dob"
                  class="form-control"
                  placeholder="Select from date"
                  formControlName="fromDate"
                  ngbDatepicker
                  [maxDate]="
                    convertDateStruct(filterForm.get('toDate').value) ?? today
                  "
                  #d="ngbDatepicker"
                  [readonly]="true"
                  (click)="d.toggle()"
                />
                <button
                  class="input-group-text"
                  type="button"
                  (click)="d.toggle()"
                >
                  <i class="feather icon-calendar icon-md text-muted"></i>
                </button>
              </div>
              <div
                *ngIf="
                  submitted && filterForm.get('fromDate').hasError('required')
                "
                class="mt-1"
              >
                <small class="text-danger">Please select From Date.</small>
              </div>
            </div>
            <div class="mb-4">
              <label for="dob" class="form-label"
                >To Date <span>*</span>
              </label>
              <div class="input-group">
                <input
                  id="dob"
                  class="form-control"
                  placeholder="Select to date"
                  formControlName="toDate"
                  ngbDatepicker
                  [minDate]="
                    convertDateStruct(filterForm.get('fromDate').value) ?? null
                  "
                  [maxDate]="today"
                  #d2="ngbDatepicker"
                  [readonly]="true"
                  (click)="d2.toggle()"
                />
                <button
                  class="input-group-text"
                  type="button"
                  (click)="d2.toggle()"
                >
                  <i class="feather icon-calendar icon-md text-muted"></i>
                </button>
              </div>
              <div
                *ngIf="
                  submitted && filterForm.get('toDate').hasError('required')
                "
                class="mt-1"
              >
                <small class="text-danger">Please select To Date.</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4" *ngIf="suspendedPlanFilter">
          <label class="form-label mb-2">Select Suspended Plan</label>
          <ng-select
            formControlName="planId"
            id="adviserId"
            placeholder="Select Plan"
            [loading]="plansLoader"
          >
            <ng-option *ngFor="let plan of suspendedPlans" [value]="plan._id">{{
              plan?.planName
            }}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer border-none">
    <button type="button" class="btn btn-danger" (click)="clearFilter()">
      Clear Filter
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      (click)="activeModal.close()"
    >
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()">
      Confirm
    </button>
  </div>
</form>
