import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { InsurancePlansService } from '../../../core/services/insurance-plans.service';
import { InsurancePlan } from '../../models/insurance-plan.model';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrl: './table-filter.component.scss',
})
export class TableFilterComponent implements OnInit {
  @Input() filter: {
    dateFilter: string;
    fromDate?: string;
    toDate?: string;
    planId: string;
  } = {
    dateFilter: '',
    fromDate: '',
    toDate: '',
    planId: '',
  };

  @Input() suspendedPlanFilter = false;

  //  FormGroup instance
  filterForm: FormGroup;

  // Boolean flag to check the custom form.
  customFilter: boolean = false;

  // Today's date stored to restrict future dates.
  today: NgbDateStruct;

  // Boolean flag to check the submitted state of the form.
  submitted: boolean = false;

  plansLoader = false;

  suspendedPlans: InsurancePlan[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private calender: NgbCalendar,
    private planService: InsurancePlansService
  ) {}

  ngOnInit(): void {
    if (this.suspendedPlanFilter) {
      this.getSuspendedPlans();
    }
    // Initialize the today variable.
    this.today = this.calender.getToday();

    // Initialize the form.
    this.Initialize();

    if (this.filter) {
      this.filterForm.patchValue({
        dateFilter: this.filter.dateFilter,
        fromDate: this.convertToDateStruct(this.filter.fromDate),
        toDate: this.convertToDateStruct(this.filter.toDate),
      });
    }
    if (this.filter.dateFilter == 'custom') {
      this.customFilter = true;
      this.addValidators();
    }
  }

  // function to initialize the form.
  Initialize() {
    this.filterForm = new FormGroup({
      dateFilter: new FormControl(null),
      fromDate: new FormControl(null),
      toDate: new FormControl(null),
      planId: new FormControl(null),
    });
  }

  // Function to check the custom status of the form.
  toggleCustom(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (value == 'custom') {
      this.customFilter = true;
    } else {
      this.customFilter = false;
    }

    this.addValidators();
  }

  addValidators() {
    if (this.customFilter) {
      const formControls = ['fromDate', 'toDate'];
      for (const controlName of formControls) {
        const control = this.filterForm.get(controlName);
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
      }
    } else {
      const formControls = ['fromDate', 'toDate'];
      for (const controlName of formControls) {
        const control = this.filterForm.get(controlName);
        control.clearValidators();
        control.updateValueAndValidity();
      }
    }
  }

  // Function to clear the form.
  clearFilter() {
    this.customFilter = false;
    this.Initialize();
    this.addValidators();
  }

  // Function to submit the form if valid.
  onSubmit() {
    this.submitted = true;
    if (this.filterForm.valid) {
      const payload = this.filterForm.value;
      if (payload.dateFilter == 'custom') {
        payload.fromDate = this.dateFormat(payload.fromDate);
        payload.toDate = this.dateFormat(payload.toDate);
      }
      this.activeModal.close({ filter: payload });
    }
  }

  // Function to change the date format of the form values.
  dateFormat(date: string) {
    const split = date.split('-');
    return `${split[2]}-${split[1]}-${split[0]}`;
  }

  // Function to convert date ini yyyy-mm-dd format to NgbDateStruct.
  convertToDateStruct(value: string) {
    if (!value) {
      return null;
    }
    const split = value.split('-');
    return `${split[2]}-${split[1]}-${split[0]}`;
  }

  getSuspendedPlans() {
    this.plansLoader = true;
    this.planService.getSuspendedPlans().subscribe({
      next: (response) => {
        if (response?.status === 'success') {
          this.suspendedPlans = response?.data;
          if (this.filter?.planId) {
            this.filterForm.patchValue({
              planId: this.filter?.planId,
            });
          }
        }
        this.plansLoader = false;
      },
      error: (error) => {
        this.suspendedPlans = [];
        this.plansLoader = false;
      },
    });
  }

  convertDateStruct(date: string): NgbDateStruct | null {
    if (!date) {
      return null;
    }
    const split = date.split('-');
    if (split.length === 3) {
      return {
        year: parseInt(split[2]),
        month: parseInt(split[1]),
        day: parseInt(split[0]),
      };
    }
    return null;
  }
}
