import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableHeader } from '../../interfaces/table-header.interface';
import {
  ColumnMode,
  DatatableComponent as Dt,
  SelectionType,
} from '@swimlane/ngx-datatable';
import {
  DatatableAction,
  DatatablePage,
  DatatableSort,
} from '../../interfaces/datatable-action.interface';
import { Quotation } from '../../models/quotation.model';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss',
})
export class DatatableComponent implements OnInit {
  @ViewChild(Dt, { static: false }) table: Dt;

  // The properties bindings
  @Input() headers: TableHeader[] = [];
  @Input() loadingIndicator: boolean = false;
  @Input() slNo: boolean = true;
  @Input() actionButton: boolean = false;
  @Input() filterApplied: boolean = false;
  @Input() rows: Object[] = [];
  @Input() limit: number = 10;
  @Input() totalItems: number = 1;
  @Input() externalPaging: boolean = true;
  @Input() externalSorting: boolean = true;
  @Input() search: string = null;
  @Input() actions: DatatableAction[] = [];
  @Input() sorts: { prop: string; dir: string }[] = [];
  @Input() filter: boolean = true;
  @Input() export: boolean = true;
  @Input() enableSelection: boolean = false;

  selected: Object[] = [];

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  @Output() dtOnPage = new EventEmitter<DatatablePage>();
  @Output() dtOnLimit = new EventEmitter<number>();
  @Output() dtOnSearch = new EventEmitter<string>();
  @Output() dtOnFilter = new EventEmitter<string>();
  @Output() dtOnExport = new EventEmitter<string>();
  @Output() dtOnSort = new EventEmitter<DatatableSort>();
  @Output() dtAction = new EventEmitter<{ action: string; data: any }>();
  @Output() dtButtonAction = new EventEmitter<string>();
  @Output() dtOnSend = new EventEmitter<Object[]>();

  ngOnInit(): void {}

  onLimitChange() {
    this.dtOnLimit.emit(this.limit);
    this.table.offset = 0;
  }

  onSearchChange() {
    this.dtOnSearch.emit(this.search);
    this.table.offset = 0;
  }

  onActivate(event: any) {
    if (event.event.srcElement.nodeName.toLowerCase() == 'button') {
      event.event.preventDefault();
      this.selected = []; // Resetting the selected array might not be the good use ?
    }
  }

  onSelect(selected: any) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected.selected);
  }

  getShortName(fullName: string) {
    return fullName
      .split(' ')
      .map((n) => n[0])
      .join('')
      .slice(0, 2);
  }
}
