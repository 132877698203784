import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { ErrorHandler } from '../helpers/error-handler';
import { CommonResponse } from '../../shared/interfaces/common-response.interface';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { Quotation } from '../../shared/models/quotation.model';
import { InsuranceProvider } from '../../shared/models/insurance-provider.model';
import { NetworkProvider } from '../../shared/models/network-provider.model';
import { Copay } from '../../shared/models/copay.model';
import { ActivityLog } from '../../shared/interfaces/activity-log.interface';

@Injectable({
  providedIn: 'root',
})
export class QuotationService {
  quotation: Quotation = null;

  quotation$ = new BehaviorSubject<Quotation>(null);
  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandler
  ) {}

  /**
   * Retrieves a list of quotations based on the provided payload.
   *
   * @param {Object} payload - The payload containing parameters for the quotation list retrieval.
   * @returns {Observable<CommonResponse<{ quotations: { data: Quotation[]; count: number } }>>}
   *    An observable that resolves to a common response containing the list of quotations and count.
   * @throws {Error} If an error occurs during the API call.
   */
  getQuotationsList(payload: Object): Observable<
    CommonResponse<{
      quotations: { data: Quotation[]; count: number; filteredCount: number };
    }>
  > {
    return this.apiService
      .post<
        CommonResponse<{
          quotations: {
            data: Quotation[];
            count: number;
            filteredCount: number;
          };
        }>
      >('quotation-list', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  /**
   * Retrieves a quotation with its Id.
   * @param { string } quotationId
   * @returns { Observable<Quotation> }
   * @throws {Error} If an error occurs during the API call.
   */
  getQuotation(quotationId: string): Observable<Quotation> {
    return this.apiService
      .get<CommonResponse<Quotation[]>>(`quotation/${quotationId}`)
      .pipe(
        map((response) => {
          if (response?.data) {
            this.quotation = response?.data[0];
            this.quotation$.next(this.quotation);
            return response?.data[0];
          } else {
            return null;
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * Get quotation details using qrf.
   * @param {string} qrf
   * @returns { Observable<Quotation> }
   */
  getQRFQuotation(qrf: string): Observable<Quotation> {
    return this.apiService
      .get<CommonResponse<Quotation>>(`quotations/qrf/${qrf}`)
      .pipe(
        map((response) => {
          if (response?.data) {
            return response?.data;
          } else {
            return null;
          }
        }),
        catchError(this.errorHandler.errorHandler)
      );
  }

  /**
   * Get revised quotation details.
   * @param quotationId
   * @returns
   */
  reviseQuotation(
    quotationId: string
  ): Observable<CommonResponse<{ quotationId: string }>> {
    const body: Object = { quotationId };
    return this.apiService
      .post<CommonResponse<{ quotationId: string }>>('quotation/revision', body)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  getFilterOptions(payload: Object) {
    return this.apiService
      .post<
        CommonResponse<{
          insurers: InsuranceProvider[];
          networkProviders: NetworkProvider[];
          copayForTests: Copay[];
          copayForConsultations: Copay[];
        }>
      >('filter-options', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  getActivities(payload: Object) {
    return this.apiService
      .post<
        CommonResponse<{
          activityLog: ActivityLog[];
          filteredCount: number;
          totalCount: number;
        }>
      >('activity-list', payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }

  getQuotationsSearch(payload: Object) {
    return this.apiService.post<
      CommonResponse<{ _id: string; quotationId: string }[]>
    >('quotation-search', payload);
  }

  exportExcel(
    status: string,
    dateFilter: string,
    fromDate: string,
    toDate: string
  ) {
    let url = 'list-export/' + `${status}?dateFilter=${dateFilter}&`;
    if (fromDate) {
      url = url + `fromDate=${fromDate}&`;
    }
    if (toDate) {
      url = url + `toDate=${toDate}`;
    }
    return this.apiService.getExport<Blob>(url);
  }

  exportActivityLog(
    dateFilter: string,
    fromDate: string,
    toDate: string,
    quotationIds: string[]
  ) {
    let url = 'activity-export/' + `?dateFilter=${dateFilter}&`;

    if (fromDate) {
      url = url + `fromDate=${fromDate}&`;
    }
    if (toDate) {
      url = url + `toDate=${toDate}`;
    }
    if (quotationIds) {
      url = url + `&quotationIds=${quotationIds}`;
    }

    return this.apiService.getExport<Blob>(url);
  }

  sendSuspendedEmail(payload: Object) {
    return this.apiService
      .post<CommonResponse<any>>(`quotations/send-suspended-mail`, payload)
      .pipe(catchError(this.errorHandler.errorHandler));
  }
}
